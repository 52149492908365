import React from 'react';
import PropTypes from 'prop-types';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';
import { Headline } from '@sumup/circuit-ui';
import { Grid } from '@sumup/circuit-ui/legacy';

import FullMediaBackground from '../FullMediaBackground';
import Badge from '../Badge';
import { TYPES } from '../../constants';

import * as FullMediaContentService from './FullMediaContentService';

import {
  ALIGNMENT_VERTICAL,
  ALIGNMENT_HORIZONTAL,
  COLORS,
  VIEWPORTS,
} from '~/shared/constants';
import dataSelector from '~/shared/util/data-selector';
import { mediaPropType } from '~/shared/util/shared-prop-types';
import useNavigation from '~/shared/components/Navigation/hooks/use-navigation';

const DATA_SELECTOR = 'full_media_component';

const viewportWrapperStyles = ({ viewport, theme, type, soloDesign = {} }) => {
  const isMobile = viewport === VIEWPORTS.MOBILE;
  const minHeight = FullMediaContentService.getMinHeight(
    viewport,
    type,
    soloDesign,
  );

  return css`
    ${soloDesign.stickToGrid &&
    css`
      position: relative;
      max-width: ${theme.grid.default.maxWidth};
      margin: 0 auto;
      overflow: hidden;
    `}

    ${isMobile
      ? css`
          position: relative;
          padding: ${theme.spacings.giga} 0;
        `
      : css`
          display: flex;
          padding: ${theme.spacings.exa} 0;
        `}
    min-height: ${minHeight}px;
    background-color: var(--cui-bg-subtle);
  `;
};

const heroStyles = (props = {}) => {
  const height = FullMediaContentService.getHeight(props);
  const maxHeight = FullMediaContentService.getMaxHeight(props);

  return css`
    height: ${height};
    max-height: ${maxHeight};
  `;
};

const contentStyles = ({ theme, isCircuitUiV4 }) => css`
  ${isCircuitUiV4 &&
  `${theme.mq.giga} {
    & {
      max-width: initial;
      margin: 0 90px;
      padding: 0;
    }
  }`}

  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  position: relative;

  ${theme.mq.untilMega} {
    margin: 0 auto;
  }
`;

const Content = styled(Grid)(contentStyles);

const wrapperStyles = (props = {}) => {
  const { theme, type } = props;
  const isHero = type === TYPES.HERO;

  return css`
    ${viewportWrapperStyles({ viewport: VIEWPORTS.DESKTOP, ...props })}
    ${isHero && heroStyles({ viewport: VIEWPORTS.DESKTOP, ...props })}

    ${theme.mq.kiloToMega} {
      ${viewportWrapperStyles({ viewport: VIEWPORTS.TABLET, ...props })}
      ${isHero && heroStyles({ viewport: VIEWPORTS.TABLET, ...props })}
    }

    ${theme.mq.untilKilo} {
      ${viewportWrapperStyles({ viewport: VIEWPORTS.MOBILE, ...props })}
      ${isHero && heroStyles({ viewport: VIEWPORTS.MOBILE, ...props })}
    }
  `;
};

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'type',
})(wrapperStyles);

const headlineSoloStyles = ({ theme }) => css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  color: var(--cui-fg-accent);
  margin: 0 auto;
  text-align: center;
  cursor: default;

  ${theme.mq.mega} {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -3px;
  }

  ${theme.mq.kiloToMega} {
    font-size: 66px;
    line-height: 66px;
    letter-spacing: -3px;
  }

  ${theme.mq.untilKilo} {
    font-size: 40px;
    line-height: 40px;
    font-size: clamp(30px, 12vw, 50px);
    line-height: clamp(30px, 12vw, 50px);
    margin-top: 10px;
    letter-spacing: -2px;
  }
`;
const StyledSoloBigHeadline = styled(Headline)(headlineSoloStyles);

/**
 * Full Media Content
 */
const FullMediaContent = ({
  children,
  viewport = VIEWPORTS.MOBILE,
  type = TYPES.HERO,
  doInterrupt,
  onPlay,
  onPosterImageClick,
  backgroundMedia,
  backgroundColor,
  isSlide,
  isBackgroundInFront = false,
  badge,
  badgeHorizontalAlignment,
  badgeVerticalAlignment,
  soloDesign = {},
  isCircuitUiV4,
  fullMediaId,
}) => {
  const { hasExtraLevelNavigationOnPage, hasInfoBannerOnPage } =
    useNavigation();

  const backgroundFallback = FullMediaContentService.getBackgroundMediaFallback(
    backgroundMedia,
    backgroundColor,
  );

  return (
    <Wrapper
      viewport={viewport}
      type={type}
      soloDesign={soloDesign}
      hasInfoBanner={hasInfoBannerOnPage}
      hasExtraLevelNavigation={hasExtraLevelNavigationOnPage}
      data-selector={dataSelector(`wrapper_${type}_${viewport}`, DATA_SELECTOR)}
    >
      {soloDesign.bigHeadlineFirstLine && (
        <StyledSoloBigHeadline as="h1">
          {soloDesign.bigHeadlineFirstLine}
          {soloDesign.bigHeadlineSecondLine && <br />}
          {soloDesign.bigHeadlineSecondLine || ''}
          {soloDesign.bigHeadlineThirdLine && <br />}
          {soloDesign.bigHeadlineThirdLine || ''}
        </StyledSoloBigHeadline>
      )}
      <Content
        viewport={viewport}
        data-selector={dataSelector('content', DATA_SELECTOR)}
        isCircuitUiV4={isCircuitUiV4}
      >
        {children}
      </Content>
      {badge && (
        <Badge
          alignmentHorizontal={badgeHorizontalAlignment}
          alignmentVertical={badgeVerticalAlignment}
          badge={badge}
          data-selector={dataSelector('full_media_badge', DATA_SELECTOR)}
          viewport={viewport}
        />
      )}
      {backgroundFallback && (
        <FullMediaBackground
          data-selector={dataSelector('background', DATA_SELECTOR)}
          contentType={backgroundFallback.contentType}
          data={backgroundFallback.data}
          backgroundColor={backgroundColor}
          type={type}
          doInterrupt={doInterrupt}
          onPlay={onPlay}
          onPosterImageClick={onPosterImageClick}
          isSlide={isSlide}
          viewport={viewport}
          moveToFront={isBackgroundInFront}
          fullMediaId={fullMediaId}
        />
      )}
    </Wrapper>
  );
};

FullMediaContent.propTypes = {
  children: PropTypes.array,
  viewport: PropTypes.oneOf(values(VIEWPORTS)),
  backgroundMedia: mediaPropType,
  backgroundColor: PropTypes.oneOf([COLORS.BLACK, COLORS.BLUE, COLORS.WHITE]),
  /**
   * Is the content of type banner or hero?
   */
  type: PropTypes.oneOf(values(TYPES)),
  /**
   * Appears the background to be in front of other elements?
   */
  isBackgroundInFront: PropTypes.bool,
  doInterrupt: PropTypes.bool,
  onPlay: PropTypes.func,
  badge: PropTypes.object,
  badgeHorizontalAlignment: PropTypes.oneOf(values(ALIGNMENT_HORIZONTAL)),
  badgeVerticalAlignment: PropTypes.oneOf(values(ALIGNMENT_VERTICAL)),
  onPosterImageClick: PropTypes.func,
  isSlide: PropTypes.bool,
  isCircuitUiV4: PropTypes.bool,

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
  fullMediaId: PropTypes.string,
};

/**
 * @component
 */
export default FullMediaContent;
