import { GRID_WIDTH, TYPE_HEIGHTS } from '../../constants';

import { ALIGNMENT_VERTICAL, VIEWPORTS } from '~/shared/constants';

export const getOffset = (alignment, viewport, isCircuitUiV4) => {
  // @Note: Used in A/B tests for multi-product website
  if (isCircuitUiV4) {
    if (viewport === VIEWPORTS.DESKTOP) {
      if (alignment === ALIGNMENT_VERTICAL.MIDDLE) {
        return GRID_WIDTH / 8;
      }
    }
  }
  switch (true) {
    // MOBILE
    case viewport === VIEWPORTS.MOBILE:
      return 0;

    // TABLET
    case viewport === VIEWPORTS.TABLET &&
      alignment === ALIGNMENT_VERTICAL.MIDDLE:
      return GRID_WIDTH / 8;
    case viewport === VIEWPORTS.TABLET &&
      alignment === ALIGNMENT_VERTICAL.RIGHT:
      return GRID_WIDTH / 3;

    // DESKTOP
    case viewport === VIEWPORTS.DESKTOP &&
      alignment === ALIGNMENT_VERTICAL.MIDDLE:
      return GRID_WIDTH / 4;
    case viewport === VIEWPORTS.DESKTOP &&
      alignment === ALIGNMENT_VERTICAL.RIGHT:
      return GRID_WIDTH / 2;

    default:
      return 0;
  }
};

export const getSpan = (alignment, viewport, isCircuitUiV4) => {
  // @Note: Used in A/B tests for multi-product website
  if (isCircuitUiV4) {
    if (viewport === VIEWPORTS.DESKTOP) {
      if (alignment === ALIGNMENT_VERTICAL.MIDDLE) {
        return GRID_WIDTH - 2;
      }
    }
  }
  // mobile is always full width
  if (viewport === VIEWPORTS.MOBILE) {
    return GRID_WIDTH;
  }

  // tablet column has 50% width when
  // left or right aligned and 75% when center
  if (viewport === VIEWPORTS.TABLET) {
    if (alignment === ALIGNMENT_VERTICAL.MIDDLE) {
      return (GRID_WIDTH / 4) * 3;
    }
    return (GRID_WIDTH / 3) * 2;
  }

  // desktop column has 50% width
  return GRID_WIDTH / 2;
};

export const getMinHeight = (viewport, type) => {
  const height = TYPE_HEIGHTS[viewport][type] / 3;

  // Also calculate padding into min heights
  if (viewport === VIEWPORTS.DESKTOP) {
    return height - (96 * 2) / 3;
  }

  return height;
};
