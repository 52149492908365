import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@sumup/circuit-ui/legacy';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/fp/isEmpty';

import FullMediaContentSection from '../FullMediaContentSection';
import { TYPES } from '../../constants';

import * as FullMediaContentRowService from './FullMediaContentRowService';

import { ALIGNMENT_HORIZONTAL, VIEWPORTS } from '~/shared/constants';
import dataSelector from '~/shared/util/data-selector';
import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';

const DATA_SELECTOR = 'full_media_content_row';

const horizontalAlignmentStyles = {
  [ALIGNMENT_HORIZONTAL.TOP]: ({ theme }) => {
    const baseIndex = theme.zIndex.absolute;
    const rowIndex = baseIndex + 2;

    return css`
      align-items: flex-start;
      // section content could be longer than default min-height of a row,
      // raise z-index to prevent elements hidden by other rows
      z-index: ${rowIndex};
    `;
  },
  [ALIGNMENT_HORIZONTAL.MIDDLE]: ({ theme }) => {
    const baseIndex = theme.zIndex.absolute;
    const rowIndex = baseIndex + 1;

    return css`
      align-items: center;
      z-index: ${rowIndex};
    `;
  },
  [ALIGNMENT_HORIZONTAL.BOTTOM]: ({ theme }) => css`
    align-items: flex-end;
    z-index: ${theme.zIndex.absolute};
  `,
};

const contentRowStyles = ({ alignment, theme, fullMediaType }) => {
  const minHeightDesktop = FullMediaContentRowService.getMinHeight(
    VIEWPORTS.DESKTOP,
    fullMediaType,
  );
  const minHeightTablet = FullMediaContentRowService.getMinHeight(
    VIEWPORTS.TABLET,
    fullMediaType,
  );
  const minHeightMobile = FullMediaContentRowService.getMinHeight(
    VIEWPORTS.MOBILE,
    fullMediaType,
  );

  return css`
    display: flex;
    flex-basis: 100%;
    margin: 0 auto;
    min-height: ${minHeightDesktop}px;
    ${horizontalAlignmentStyles[alignment]({ theme })}

    ${theme.mq.kiloToMega} {
      min-height: ${minHeightTablet}px;
    }

    ${theme.mq.untilKilo} {
      min-height: ${minHeightMobile}px;
    }
  `;
};

const ContentRow = styled(Row)(contentRowStyles);

/**
 * Full Media Content Row
 */
const FullMediaContentRow = ({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical,
  textMode,
  content,
  viewport = VIEWPORTS.MOBILE,
  isActive,
  isSlide,
  isFirstSlide,
  type = TYPES.HERO,
  trackingContentEntry,
  isCircuitUiV4,
}) => {
  // @Note: The second condition concerns the A/B tests for multi-product website
  if (isEmpty(content) || (!isActive && isCircuitUiV4)) {
    return null;
  }

  const span = FullMediaContentRowService.getSpan(
    alignmentVertical,
    viewport,
    isCircuitUiV4,
  ).toString();
  const skip = FullMediaContentRowService.getOffset(
    alignmentVertical,
    viewport,
    isCircuitUiV4,
  ).toString();

  return (
    <ContentRow
      fullMediaType={type}
      alignment={alignmentHorizontal}
      data-selector={dataSelector('row', DATA_SELECTOR)}
    >
      {isActive ? (
        <Col span={span} skip={skip}>
          <FullMediaContentSection
            textMode={textMode}
            alignmentContent={alignmentVertical}
            content={content}
            type={type}
            data-selector={dataSelector(
              'full_media_content_section',
              DATA_SELECTOR,
            )}
            isSlide={isSlide}
            isFirstSlide={isFirstSlide}
            trackingContentEntry={trackingContentEntry}
            isCircuitUiV4={isCircuitUiV4}
          />
        </Col>
      ) : null}
    </ContentRow>
  );
};

FullMediaContentRow.propTypes = {
  ...FullMediaContentSection.contentProps,
  viewport: PropTypes.oneOf(values(VIEWPORTS)),
  type: PropTypes.oneOf(values(TYPES)),
  isActive: PropTypes.bool,
  isSlide: PropTypes.bool,
  isFirstSlide: PropTypes.bool,
  trackingContentEntry: trackingContentEntryPropType,
  isCircuitUiV4: PropTypes.bool,
};

/**
 * @component
 */
export default FullMediaContentRow;
