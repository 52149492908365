import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import PosterVideo from '~/shared/components/PosterVideo';
import Video from '~/shared/components/Video';

const wrapperStyles = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: hidden;
`;

const Wrapper = styled('div')(wrapperStyles);

/**
 * FullMediaVideo
 */
const FullMediaVideo = ({
  openInModal = false,
  src,
  title,
  posterImage,
  aspectRatio,
  showPlayButton = true,
  posterWidth,
  doInterrupt,
  onPlay,
  onPosterImageClick,
  isSlide,
  ...rest
}) => {
  if (!src) {
    return null;
  }

  return (
    <Wrapper>
      <PosterVideo
        video={{
          ...rest,
          src,
          title,
          onPlay,
          doInterrupt,
          isSlide,
        }}
        showPlayButton={showPlayButton}
        aspectRatio={aspectRatio}
        openInModal={openInModal}
        posterImage={posterImage}
        alignToParent={true}
        posterWidth={posterWidth}
        onPosterImageClick={onPosterImageClick}
      />
    </Wrapper>
  );
};

FullMediaVideo.propTypes = {
  ...Video.propTypes,
  posterImage: PropTypes.object,
  openInModal: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  posterWidth: PropTypes.number,
  doInterrupt: PropTypes.bool,
  onPlay: PropTypes.func,
  onPosterImageClick: PropTypes.func,
  isSlide: PropTypes.bool,
};

/**
 * @component
 */
export default FullMediaVideo;
