import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/fp/isEmpty';
import Tagger from '@elbwalker/tagger';

import FullMediaContent from './components/FullMediaContent';
import FullMediaContentRow from './components/FullMediaContentRow';
import FullMediaContentSection from './components/FullMediaContentSection';
import FullMediaBackground from './components/FullMediaBackground';
import * as FullMediaService from './FullMediaService';
import { TYPES } from './constants';

import useViewportName from '~/shared/hooks/use-viewport-name';
import useMeasurePerformance from '~/shared/hooks/use-measure-performance';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import dataSelector from '~/shared/util/data-selector';
import {
  ALIGNMENT_HORIZONTAL,
  ALIGNMENT_VERTICAL,
  VIEWPORTS,
} from '~/shared/constants';

const DATA_SELECTOR = 'full_media';

/**
 * A Full Media component which supports an array of different configurations as:
 * Flexible positioning of elements, various backgrounds and media types.
 */
const FullMedia = ({
  contentMobile,
  contentDesktop,
  contentTablet,
  type,
  doInterrupt = false,
  onPlay,
  badge,
  onPosterImageClick,
  isSlide = false,
  isFirstSlide = false,
  isCircuitUiV4 = false,
  index,
  id: fullMediaId,
  taggingContext,
  taggingEntity,
  taggingAction,
  taggingTrigger,
}) => {
  useMeasurePerformance('full-media-component-render');

  const tagger = React.useMemo(() => {
    if (!taggingAction || !taggingEntity || !taggingTrigger) {
      return {};
    }
    const t = Tagger();

    const componentContext = { component: 'full_media' };

    const fullTaggingContext = taggingContext
      ? { ...taggingContext, ...componentContext }
      : componentContext;

    const info = {
      ...t.entity(taggingEntity),
      ...t.action(taggingTrigger, taggingAction),
      ...t.context(fullTaggingContext),
    };

    return info;
  }, [taggingEntity, taggingAction, taggingTrigger, taggingContext]);

  const { experiments } = useOptimizelyData();
  const viewportName = useViewportName();

  if (!contentMobile && !contentDesktop && !contentTablet) {
    return null;
  }

  const viewports = {
    [VIEWPORTS.MOBILE]: getVariationComponent(contentMobile, experiments),
    [VIEWPORTS.DESKTOP]: getVariationComponent(contentDesktop, experiments),
    [VIEWPORTS.TABLET]: getVariationComponent(contentTablet, experiments),
  };
  const badgeVariation = getVariationComponent(badge, experiments);

  const viewportContent = viewports[viewportName] || {};
  const {
    id,
    name,
    contentType,
    alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
    alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
    textMode,
    content,
    backgroundMedia,
    backgroundColor,
    badgeVerticalAlignment = ALIGNMENT_VERTICAL.LEFT,
    badgeHorizontalAlignment = ALIGNMENT_HORIZONTAL.TOP,
    soloDesign = {},
  } = viewportContent;
  const backgroundMediaVariation = getVariationComponent(
    backgroundMedia,
    experiments,
  );

  const hasTextContent = !isEmpty(content);
  const trackingContentEntry = {
    contentType,
    contentEntryName: name,
    contentEntryId: id,
  };

  let asHTag;
  let contentWithTags;

  if (hasTextContent) {
    asHTag = FullMediaService.getFullMediaHeadingTag(
      isSlide,
      isFirstSlide,
      index,
    );
    contentWithTags = FullMediaService.getContentWithHeadingTags(
      content,
      asHTag,
    );
  }

  return (
    <div
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:full_media"
      {...tagger}
    >
      <FullMediaContent
        viewport={viewportName}
        type={type}
        onPlay={onPlay}
        onPosterImageClick={onPosterImageClick}
        doInterrupt={doInterrupt}
        backgroundMedia={backgroundMediaVariation}
        backgroundColor={backgroundColor}
        isBackgroundInFront={!hasTextContent}
        data-selector={dataSelector(
          `full_media_content_${viewportName}`,
          DATA_SELECTOR,
        )}
        badge={badgeVariation}
        badgeVerticalAlignment={badgeVerticalAlignment}
        badgeHorizontalAlignment={badgeHorizontalAlignment}
        soloDesign={soloDesign}
        isCircuitUiV4={isCircuitUiV4}
        fullMediaId={fullMediaId}
      >
        {values(ALIGNMENT_HORIZONTAL).map((rowAlignment) => (
          <FullMediaContentRow
            key={rowAlignment}
            alignmentHorizontal={rowAlignment}
            alignmentVertical={alignmentVertical}
            textMode={textMode}
            content={contentWithTags}
            viewport={viewportName}
            type={type}
            data-selector={dataSelector(
              `full_media_content_row_${rowAlignment}`,
              DATA_SELECTOR,
            )}
            isActive={alignmentHorizontal === rowAlignment}
            isSlide={isSlide}
            isFirstSlide={isFirstSlide}
            trackingContentEntry={trackingContentEntry}
            isCircuitUiV4={isCircuitUiV4}
          />
        ))}
      </FullMediaContent>
    </div>
  );
};

const backgroundMediaPropType = PropTypes.shape(FullMediaBackground.propTypes);
const backgroundMediaVariations = PropTypes.oneOfType([
  PropTypes.shape({
    variations: PropTypes.arrayOf(backgroundMediaPropType),
  }),
  backgroundMediaPropType,
]);
const contentPropType = PropTypes.shape({
  ...FullMediaContentSection.contentProps,
  backgroundMedia: backgroundMediaVariations,
  backgroundColor: PropTypes.string,
});
const contentPropTypeVariations = PropTypes.oneOfType([
  PropTypes.shape({
    variations: PropTypes.arrayOf(contentPropType),
  }),
  contentPropType,
]);

FullMedia.propTypes = {
  contentDesktop: contentPropTypeVariations,
  contentMobile: contentPropTypeVariations,
  contentTablet: contentPropTypeVariations,
  type: PropTypes.oneOf(values(TYPES)),
  doInterrupt: PropTypes.bool,
  onPlay: PropTypes.func,
  badge: PropTypes.object,
  onPosterImageClick: PropTypes.func,
  isSlide: PropTypes.bool,
  isFirstSlide: PropTypes.bool,
  index: PropTypes.number,
  isCircuitUiV4: PropTypes.bool,
  id: PropTypes.string,
  taggingContext: PropTypes.object,
  taggingEntity: PropTypes.string,
  taggingAction: PropTypes.string,
  taggingTrigger: PropTypes.string,
};

/**
 * @component
 */
export default FullMedia;
