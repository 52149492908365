import PropTypes from 'prop-types';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { COLORS } from '~/shared/constants';

const backgroundColorStyles = {
  [COLORS.BLACK]: () => css`
    background-color: var(--cui-bg-strong);
  `,
  [COLORS.BLUE]: () => css`
    background-color: var(--cui-bg-accent-strong);
  `,
  [COLORS.WHITE]: () => css`
    background-color: var(--cui-bg-normal);
  `,
};

const coloredBackgroundStyles = ({ color = COLORS.WHITE }) => {
  const backgroundColorStyle = backgroundColorStyles[color];

  return css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    ${backgroundColorStyle && backgroundColorStyle()}
  `;
};

/**
 * FullMediaBackgroundColored
 */
const FullMediaBackgroundColored = styled('span', {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== 'color',
})(coloredBackgroundStyles);

FullMediaBackgroundColored.propTypes = {
  color: PropTypes.string,
};

/**
 * @component
 */
export default FullMediaBackgroundColored;
