import React, { useContext } from 'react';
import { Button, useModal } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import POSVideoModal from './POSVideoModal';

import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';
import dataSelector from '~/shared/util/data-selector';
import { CircuitEmotionModalProps } from '~/shared/types/shared';

const ButtonContainer = styled('div')(
  () => css`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
  `,
);
const LeftPlaceholder = styled('div')(
  ({ theme }) => css`
    width: 50%;
    height: 100%;

    ${theme.mq.untilMega} {
      display: none;
    }
  `,
);
const RightPlaceholder = styled('div')(
  ({ theme }) => css`
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.mq.untilMega} {
      width: 100%;
    }
  `,
);
const OpenVideoButton = styled(Button)(
  () => css`
    align-self: flex-end;
    margin-bottom: 20px;
  `,
);

// POS FullMedia that is going to be used in an A/B test
export const TARGET_FULL_MEDIA_IDS = [
  '7g1fHSs9mXQ7LTHlDU2QVx', // mobile
  'ZI6CFKs09mIwAKDihSmTr', // desktop+tablet
];

export const FORCE_SHOW_QUERY_PARAM_NAME = 'showFullVideoButton';

const VIDEO_SOURCES = {
  'en-gb':
    'https://videos.ctfassets.net/txhaodyqr481/4UIBVzugd2un5d7ohOtChB/29c6f99d303aa95c004a94e46c54ac4d/SUM_UP_POS_FINAL_-_GRADE_SOUND_MIX_-_ENG_GBP_-_With_Subs.mp4',
  'en-ie':
    'https://videos.ctfassets.net/txhaodyqr481/4UIBVzugd2un5d7ohOtChB/6af8f421752d463547000936e84123b5/SUM_UP_POS_-_GRADE_MIX_V2_with_Subs.mp4',
  'fr-fr':
    'https://videos.ctfassets.net/txhaodyqr481/4UIBVzugd2un5d7ohOtChB/6ca36b38356dbfd725a8a4ff85633f97/SUM_UP_POS_FINAL_-_GRADE_SOUND_MIX_-_FR_With_Subs.mp4',
  'it-it':
    'https://videos.ctfassets.net/txhaodyqr481/4UIBVzugd2un5d7ohOtChB/d54e2cfd125844d7e54b96ef5974aeeb/SUM_UP_POS_FINAL_-_GRADE_SOUND_MIX_-_IT_with_subs.mp4',
  'de-de':
    'https://videos.ctfassets.net/txhaodyqr481/4UIBVzugd2un5d7ohOtChB/44020c10f7a74a62fa6d4681d5176ba0/SUM_UP_POS_FINAL_-_GRADE_SOUND_MIX_-_DE_with_Subs.mp4',
  'es-es':
    'https://videos.ctfassets.net/txhaodyqr481/4UIBVzugd2un5d7ohOtChB/2bdc5d3bd5a1a3a896d461745120351c/SUM_UP_POS_FINAL_-_GRADE_SOUND_MIX_-_ESP_with_Subs.mp4',
};
const BUTTON_TEXT = {
  'en-gb': 'Watch full video',
  'en-ie': 'Watch full video',
  'fr-fr': 'Voir la vidéo',
  'it-it': 'Guarda il video',
  'de-de': 'Video ansehen',
  'es-es': 'Ver el vídeo',
};

interface POSVideoModalButtonProps {
  fullMediaId?: string;
}

function POSVideoModalButton({ fullMediaId }: POSVideoModalButtonProps) {
  const { setModal } = useModal();
  const {
    accessibilityLabels = {},
    locale,
  }: { accessibilityLabels: { [key: string]: string }; locale: string } =
    useContext(SiteContext);
  const { query = {} } = useContext(RequestContext);
  const isTargetFullMedia = TARGET_FULL_MEDIA_IDS.includes(fullMediaId);
  const hasForceQueryParam = !!query[FORCE_SHOW_QUERY_PARAM_NAME];
  const showFullVideoButton = isTargetFullMedia || hasForceQueryParam;

  if (!showFullVideoButton) {
    return null;
  }

  const videoSourcePerLocale = VIDEO_SOURCES[locale?.toLowerCase()];

  if (!videoSourcePerLocale) {
    return null;
  }

  const textPerLocale = BUTTON_TEXT[locale?.toLowerCase()];

  if (!textPerLocale) {
    return null;
  }

  const handleClick = () => {
    setModal({
      css: () => css`
        padding-bottom: 45px !important;
      `,
      children: (
        <POSVideoModal
          videoSource={videoSourcePerLocale}
          fullMediaId={fullMediaId}
        />
      ),
      closeButtonLabel: accessibilityLabels.closeButton,
    } as CircuitEmotionModalProps);
  };

  return (
    <ButtonContainer>
      <LeftPlaceholder />
      <RightPlaceholder>
        <OpenVideoButton
          data-selector={dataSelector('open', 'pos_video_modal')}
          onClick={handleClick}
        >
          {textPerLocale}
        </OpenVideoButton>
      </RightPlaceholder>
    </ButtonContainer>
  );
}

/**
 * @component
 */
export default POSVideoModalButton;
