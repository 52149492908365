import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/fp/isEmpty';
import isPropValid from '@emotion/is-prop-valid';

import { TEXT_MODE, ALIGNMENT_ELEMENTS, TYPES } from '../../constants';

// eslint-disable-next-line max-len
import * as FullMediaContentSectionService from './FullMediaContentSectionService';

import {
  richTextPropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import RichText from '~/shared/components/RichText';
import * as fullMedia from '~/shared/components/RichText/configs/full-media';
import { ALIGNMENT_VERTICAL } from '~/shared/constants';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const alignmentElementStyles = {
  [ALIGNMENT_ELEMENTS.LEFT]: css`
    justify-content: flex-start;
  `,
  [ALIGNMENT_ELEMENTS.MIDDLE]: css`
    justify-content: center;
  `,
  [ALIGNMENT_ELEMENTS.RIGHT]: css`
    justify-content: flex-end;
  `,
};

const alignmentVerticalStyles = {
  [ALIGNMENT_VERTICAL.RIGHT]: css`
    margin-right: 0;
    margin-left: auto;
  `,
  [ALIGNMENT_VERTICAL.MIDDLE]: css`
    margin-left: auto;
    margin-right: auto;
  `,
};

const wrapperStyles = ({ alignmentElement, alignmentVertical }) => css`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${alignmentElementStyles[alignmentElement]} ${alignmentVerticalStyles[
    alignmentVertical
  ]};
`;

const Wrapper = styled('div', { shouldForwardProp: isPropValid })(
  wrapperStyles,
);

/**
 * Full Media Content Section
 */
const FullMediaContentSection = ({
  textMode = TEXT_MODE.DARK,
  alignmentContent = ALIGNMENT_ELEMENTS.LEFT,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  content = {},
  type = TYPES.HERO,
  isSlide,
  isFirstSlide,
  trackingContentEntry = {},
  isCircuitUiV4 = false,
}) => {
  const { featureToggles } = useOptimizelyData();
  if (isEmpty(content)) {
    return null;
  }
  // @Note: Temporary helper. Needs to be removed when implementing the multi-product website
  const renderNodeHelper = isCircuitUiV4
    ? fullMedia.renderNodeCustom
    : fullMedia.renderNode;

  const hasPrimaryHeading = FullMediaContentSectionService.hasPrimaryHeading(
    type,
    isSlide,
    isFirstSlide,
  );

  return (
    <Wrapper
      textMode={textMode}
      alignmentElement={alignmentContent}
      alignmentVertical={alignmentVertical}
    >
      <RichText
        richText={content}
        renderNode={renderNodeHelper({
          alignmentContent,
          textMode,
          hasPrimaryHeading,
          isSlide,
          trackingContentEntry,
          featureToggles,
        })}
        renderMark={fullMedia.renderMark({ alignmentContent, textMode })}
      />
    </Wrapper>
  );
};

const contentProps = {
  content: richTextPropType,
  alignmentContent: PropTypes.oneOf(values(ALIGNMENT_ELEMENTS)),
  alignmentVertical: PropTypes.oneOf(values(ALIGNMENT_VERTICAL)),
  textMode: PropTypes.oneOf(values(TEXT_MODE)),
};

FullMediaContentSection.contentProps = contentProps;
FullMediaContentSection.propTypes = {
  ...contentProps,
  type: PropTypes.oneOf(values(TYPES)),
  isSlide: PropTypes.bool,
  isFirstSlide: PropTypes.bool,
  trackingContentEntry: trackingContentEntryPropType,
  isCircuitUiV4: PropTypes.bool,
};

/**
 * @component
 */
export default FullMediaContentSection;
