import {
  BACKGROUND_MEDIA_TYPES,
  TYPE_HEIGHTS,
  SOLO_MAX_HEIGHTS,
  DESKTOP_MAX_HEIGHT,
} from '../../constants';

import { VIEWPORTS } from '~/shared/constants';

export const getMinHeight = (viewport, type, soloDesign = {}) => {
  if (soloDesign.minHeight) {
    return soloDesign.minHeight;
  }

  return TYPE_HEIGHTS[viewport][type];
};

export const calculateMinusOffset = ({
  viewport,
  theme,
  hasInfoBanner,
  hasExtraLevelNavigation,
}) => {
  let offset = ` - ${
    theme.spacings.nav[viewport] || theme.spacings.nav.desktop
  }`;

  if (hasInfoBanner) {
    offset += ` - ${
      theme.spacings.infoBanner[viewport] || theme.spacings.infoBanner.desktop
    }`;
  }

  if (hasExtraLevelNavigation) {
    offset += ` - ${theme.spacings.tertiaryNav.desktop}`;
  }

  return offset;
};

export const getHeight = (props = {}) => {
  const { viewport, soloDesign = {} } = props;

  if (soloDesign.shouldAdjustHeight || viewport === VIEWPORTS.DESKTOP) {
    const minusOffset = calculateMinusOffset(props);

    return `calc(100vh${minusOffset})`;
  }

  return 'auto';
};

export const getMaxHeight = ({ viewport, soloDesign = {} } = {}) => {
  if (soloDesign.maxHeight) {
    return soloDesign.maxHeight;
  }

  if (soloDesign.shouldAdjustHeight) {
    return `${SOLO_MAX_HEIGHTS[viewport]}px`;
  }

  return viewport === VIEWPORTS.DESKTOP ? `${DESKTOP_MAX_HEIGHT}px` : 'initial';
};

export const getBackgroundMediaFallback = (
  backgroundMedia,
  backgroundColor,
) => {
  if (backgroundMedia?.contentType) {
    return backgroundMedia;
  }

  if (backgroundColor) {
    return {
      contentType: BACKGROUND_MEDIA_TYPES.COLORED,
      data: {
        color: backgroundColor,
      },
    };
  }

  return undefined;
};
