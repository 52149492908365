import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { elb } from '@elbwalker/walker.js';

import * as Analytics from '~/shared/services/analytics';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { VIEWPORTS } from '~/shared/constants';
import dataSelector from '~/shared/util/data-selector';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

type DocumentWebkit = Document & {
  webkitFullscreenElement(): Promise<void>;
  webkitExitFullScreen(): Promise<void>;
};

type VideoElmWebkit = HTMLVideoElement & {
  webkitRequestFullscreen(): Promise<void>;
};

const StyledModalWrapper = styled('div')(
  ({ theme }) => css`
    overflow: hidden;
    outline: none;
    margin: 20px auto auto;

    ${theme.mq.kilo} {
      opacity: 1;
      max-height: 90vh;
      max-width: 90%;
      min-width: 450px;
      width: 70vw;
      position: relative;
    }
  `,
);

const StyledVideo = styled('video')(
  () => css`
    width: 100%;
    height: 100%;
  `,
);

const PERCENTAGE_THRESHOLDS = [10, 25, 50, 75, 90];
const PREFIX_GA_ACTION = 'POSMKTG-1239-';
const BASE_GA_TRACKING_FIELDS = {
  event: 'interaction',
  target: 'Mkt_Web',
  destinationCategory: Analytics.destinationCategory.UI_OPERATION,
  destinationUrl: undefined,
};

function POSVideoModal({
  videoSource,
  fullMediaId,
}: {
  videoSource?: string;
  fullMediaId?: string;
}) {
  const videoWasPaused = useRef(false);
  const trackedPercentages = useRef([]);
  const videoElement = useRef<HTMLVideoElement>();
  const viewport = useViewportName();

  useEffect(() => {
    if (viewport === VIEWPORTS.MOBILE) {
      const fullscreenElement =
        document.fullscreenElement ||
        (document as DocumentWebkit).webkitFullscreenElement;

      if (!fullscreenElement) {
        if (videoElement.current.requestFullscreen) {
          videoElement.current.requestFullscreen();
        } else if (
          (videoElement.current as VideoElmWebkit).webkitRequestFullscreen
        ) {
          (videoElement.current as VideoElmWebkit).webkitRequestFullscreen();
        }
        return;
      }

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (
        (videoElement.current as VideoElmWebkit).webkitRequestFullscreen
      ) {
        (document as DocumentWebkit).webkitExitFullScreen();
      }
    }
  }, [viewport]);

  const handlePlay = () => {
    const action = videoWasPaused.current
      ? `${PREFIX_GA_ACTION}video-relaunched`
      : `${PREFIX_GA_ACTION}video-started`;
    Analytics.sendEvent({
      action,
      ...BASE_GA_TRACKING_FIELDS,
    });
    sendNinetailedEvent(action);

    elb(`video ${videoWasPaused.current ? 'relaunched' : 'started'}`, {
      video_id: fullMediaId,
    });

    if (videoElement.current?.currentTime <= 0) {
      trackedPercentages.current = [];
    }
  };
  const handlePause = () => {
    videoWasPaused.current = true;
  };
  const handleFinished = () => {
    const action = `${PREFIX_GA_ACTION}video-completed`;
    Analytics.sendEvent({
      action,
      ...BASE_GA_TRACKING_FIELDS,
    });
    sendNinetailedEvent(action);
    elb('video completed', {
      video_id: fullMediaId,
    });
  };
  const handlePercentage = () => {
    if (!videoElement.current) {
      return;
    }

    const percentagePlayed = Math.round(
      (videoElement.current.currentTime / videoElement.current.duration) * 100,
    );
    PERCENTAGE_THRESHOLDS.forEach((threshold) => {
      const shouldTrackPercentage =
        percentagePlayed > threshold &&
        !trackedPercentages.current.includes(threshold);
      if (shouldTrackPercentage) {
        const action = `${PREFIX_GA_ACTION}video-progress-${threshold}`;
        Analytics.sendEvent({
          action,
          ...BASE_GA_TRACKING_FIELDS,
        });
        sendNinetailedEvent(action);
        trackedPercentages.current.push(threshold);
        elb('video-progress reached', {
          video_id: fullMediaId,
          option: `${threshold}%`,
        });
      }
    });
  };

  if (!videoSource) {
    return null;
  }

  return (
    <StyledModalWrapper
      data-selector={dataSelector('modal', 'pos_video_modal')}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <StyledVideo
        data-selector={dataSelector('video', 'pos_video_modal')}
        ref={videoElement}
        controls
        autoPlay
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleFinished}
        onTimeUpdate={handlePercentage}
      >
        <source src={videoSource} type="video/mp4" />
      </StyledVideo>
    </StyledModalWrapper>
  );
}

POSVideoModal.propTypes = {
  videoSource: PropTypes.string,
  children: PropTypes.object,
};

/**
 * @component
 */
export default POSVideoModal;
