import {
  contentHasHeadings,
  mapHeadingsContent,
} from '~/shared/components/RichText/util';
import getHeadingTag from '~/shared/util/get-heading-tag';
import { COLORS } from '~/shared/constants';

export function getContentWithHeadingTags(content, asHTag) {
  if (!content) {
    return undefined;
  }

  const hasHeadings = contentHasHeadings(content);
  const as = hasHeadings ? asHTag : 'p';
  const taggedContent = mapHeadingsContent(content, as);

  return taggedContent;
}

export const getFullMediaHeadingTag = (isSlide, isFirstSlide, index) => {
  if (!isSlide) {
    return getHeadingTag(index);
  }

  if (isFirstSlide && index === 0) {
    return 'h1';
  }

  return 'h2';
};

export const getBackgroundColor = (color) => {
  switch (color) {
    case COLORS.BLACK:
      return 'var(--cui-bg-strong)';
    case COLORS.BLUE:
      return 'var(--cui-bg-accent-strong)';
    case COLORS.WHITE:
      return 'var(--cui-bg-normal)';
    default:
      return 'var(--cui-bg-normal)';
  }
};
