import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';

// eslint-disable-next-line max-len
import * as FullMediaBackgroundVideoService from './FullMediaBackgroundVideoService';

import { VIEWPORTS, BACKGROUND_MAX_WIDTHS } from '~/shared/constants';
import BackgroundImage from '~/shared/components/BackgroundImage';
import POSVideoModalButton from '~/shared/services/optimizely/pos-marketing/POSMKTG-1239-video-popup/POSVideoModalButton';

const wrapperBaseStyles = () => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: hidden;
`;

const Wrapper = styled('div')(wrapperBaseStyles);

const videoStyles = () => css`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Video = styled('video')(videoStyles);

const BackgroundVideo = ({
  mp4,
  webm,
  placeholder,
  alt = 'background video',
  viewport,
  isLiteMode,
  fullMediaId,
  ...rest
}) => {
  const [renderVideo, setRenderVideo] = useState(false);

  useEffect(() => {
    setRenderVideo(true);
  }, []);

  if (!mp4 && !webm && !placeholder) {
    return null;
  }

  const placeholderSrc = FullMediaBackgroundVideoService.getPlaceholderUrl(
    placeholder,
    viewport,
  );

  if ((!renderVideo && placeholderSrc) || isLiteMode) {
    return (
      <>
        <BackgroundImage
          data-selector="FALLBACK_IMAGE"
          url={placeholder}
          alt={alt}
          width={BACKGROUND_MAX_WIDTHS[viewport]}
          isLiteMode={isLiteMode}
        />
        <POSVideoModalButton fullMediaId={fullMediaId} />
      </>
    );
  }

  return (
    <Wrapper {...rest}>
      <Video
        preload="metadata"
        autoPlay
        muted
        playsInline
        loop
        data-selector="hero-background-video"
        poster={placeholderSrc}
      >
        {webm && <source src={webm} type="video/webm" />}
        {mp4 && <source src={mp4} type="video/mp4" />}
      </Video>
      <POSVideoModalButton fullMediaId={fullMediaId} />
    </Wrapper>
  );
};

BackgroundVideo.propTypes = {
  /**
   * The mp4 video source URL.
   */
  mp4: PropTypes.string,
  /**
   * * The webM video source URL.
   */
  webm: PropTypes.string,
  /**
   * A fallback image that's shown while the video is loading.
   * Ideally it should match the first frame of the video.
   */
  placeholder: PropTypes.string,
  /**
   * An alternative description of the video. Crucial for accessibility.
   */
  alt: PropTypes.string,

  viewport: PropTypes.oneOf(values(VIEWPORTS)),
  /**
   * Checks if the user has good enough connection and hardware
   * to make use of our most demanding features.
   */
  isLiteMode: PropTypes.bool,
  fullMediaId: PropTypes.string,
};

/**
 * @component
 */
export default BackgroundVideo;
