import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { css, ThemeContext } from '@emotion/react';
import styled from '@emotion/styled';
import values from 'lodash/fp/values';
import isEmpty from 'lodash/fp/isEmpty';

import { BACKGROUND_MEDIA_TYPES, TYPES } from '../../constants';
import { getBackgroundColor } from '../../FullMediaService';
import FullMediaBackgroundColored from '../FullMediaBackgroundColored';
import FullMediaBackgroundVideo from '../FullMediaBackgroundVideo';
import FullMediaVideo from '../FullMediaVideo';

import BackgroundImage from '~/shared/components/BackgroundImage';
import useIsLiteMode from '~/shared/hooks/use-is-lite-mode';
import dataSelector from '~/shared/util/data-selector';
import { VIEWPORTS, BACKGROUND_MAX_WIDTHS } from '~/shared/constants';

const DATA_SELECTOR = 'full_media_background';

const wrapperStyles = ({ moveToFront, theme }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${moveToFront ? theme.zIndex.fullMediaBgMoveToFront : 0};
  height: 100%;
  width: 100%;
`;

const Wrapper = styled('figure')(wrapperStyles);

const getMedia = (
  contentType,
  data,
  type,
  viewport,
  moveToFront,
  theme,
  doInterrupt,
  onPlay,
  onPosterImageClick,
  isSlide,
  isLiteMode,
  backgroundColor,
  fullMediaId,
) => {
  const fallbackBgColor = getBackgroundColor(backgroundColor);
  const width = BACKGROUND_MAX_WIDTHS[viewport];

  const medias = {
    [BACKGROUND_MEDIA_TYPES.COLORED]: {
      Component: FullMediaBackgroundColored,
      props: {
        ...data,
      },
    },
    [BACKGROUND_MEDIA_TYPES.IMAGE]: {
      Component: BackgroundImage,
      props: {
        ...data,
        color: fallbackBgColor,
        width,
      },
    },
    [BACKGROUND_MEDIA_TYPES.BACKGROUND_VIDEO]: {
      Component: FullMediaBackgroundVideo,
      props: {
        ...data,
        viewport,
        isLiteMode,
        fullMediaId,
      },
    },
    [BACKGROUND_MEDIA_TYPES.VIDEO]: {
      Component: FullMediaVideo,
      props: {
        ...data,
        showPlayButton: moveToFront,
        openInModal: !moveToFront,
        posterWidth: width,
        viewport,
        type,
        doInterrupt,
        onPlay,
        onPosterImageClick,
        isSlide,
        posterImage: {
          ...data.posterImage,
          fallbackColor: fallbackBgColor,
        },
      },
    },
  };

  const { Component, props } = medias[contentType] || {};

  return Component ? (
    <Component
      data-selector={dataSelector('media', DATA_SELECTOR)}
      {...props}
    />
  ) : null;
};

/**
 * FullMediaBackground
 */
const FullMediaBackground = ({
  contentType = BACKGROUND_MEDIA_TYPES.COLORED,
  type = TYPES.HERO,
  viewport = VIEWPORTS.MOBILE,
  moveToFront = false,
  doInterrupt,
  onPlay,
  onPosterImageClick,
  isSlide,
  data,
  backgroundColor,
  fullMediaId,
}) => {
  const theme = useContext(ThemeContext);
  const isLiteMode = useIsLiteMode();

  if (!contentType || isEmpty(data)) {
    return null;
  }

  return (
    <Wrapper
      moveToFront={moveToFront}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
    >
      {getMedia(
        contentType,
        data,
        type,
        viewport,
        moveToFront,
        theme,
        doInterrupt,
        onPlay,
        onPosterImageClick,
        isSlide,
        isLiteMode,
        backgroundColor,
        fullMediaId,
      )}
    </Wrapper>
  );
};

FullMediaBackground.propTypes = {
  contentType: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.oneOf(values(TYPES)),
  viewport: PropTypes.oneOf(values(VIEWPORTS)),
  moveToFront: PropTypes.bool,
  doInterrupt: PropTypes.bool,
  onPlay: PropTypes.func,
  onPosterImageClick: PropTypes.func,
  isSlide: PropTypes.bool,
  backgroundColor: PropTypes.string,
  fullMediaId: PropTypes.string,
};

/**
 * @component
 */
export default FullMediaBackground;
