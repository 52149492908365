import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';

import {
  VIEWPORTS,
  ALIGNMENT_VERTICAL,
  ALIGNMENT_HORIZONTAL,
  BADGE_TYPES,
} from '~/shared/constants';
import { FeeBadge, PricingBadge, TextBadge } from '~/shared/components/Badges';

/* eslint-disable max-len, prettier/prettier */
const topLeft = `${ALIGNMENT_HORIZONTAL.TOP}_${ALIGNMENT_VERTICAL.LEFT}`;
const topMiddle = `${ALIGNMENT_HORIZONTAL.TOP}_${ALIGNMENT_VERTICAL.MIDDLE}`;
const topRight = `${ALIGNMENT_HORIZONTAL.TOP}_${ALIGNMENT_VERTICAL.RIGHT}`;
const middleLeft = `${ALIGNMENT_HORIZONTAL.MIDDLE}_${ALIGNMENT_VERTICAL.LEFT}`;
const middleMiddle = `${ALIGNMENT_HORIZONTAL.MIDDLE}_${ALIGNMENT_VERTICAL.MIDDLE}`;
const middleRight = `${ALIGNMENT_HORIZONTAL.MIDDLE}_${ALIGNMENT_VERTICAL.RIGHT}`;
const bottomLeft = `${ALIGNMENT_HORIZONTAL.BOTTOM}_${ALIGNMENT_VERTICAL.LEFT}`;
const bottomMiddle = `${ALIGNMENT_HORIZONTAL.BOTTOM}_${ALIGNMENT_VERTICAL.MIDDLE}`;
const bottomRight = `${ALIGNMENT_HORIZONTAL.BOTTOM}_${ALIGNMENT_VERTICAL.RIGHT}`;
/* eslint-enable max-len, prettier/prettier */

const desktopTabletAlignmnetStyles = {
  [topLeft]: ({ theme }) => css`
    top: 0;
    left: calc((${theme.spacings.tera} / 2) + ${theme.spacings.tera});
  `,
  [topMiddle]: () => css`
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  `,
  [topRight]: ({ theme }) => css`
    top: 0;
    right: calc((${theme.spacings.tera} / 2) + ${theme.spacings.tera});
  `,
  [middleLeft]: () => css`
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
  `,
  [middleMiddle]: () => css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  [middleRight]: () => css`
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
  `,
  [bottomLeft]: ({ theme }) => css`
    bottom: 0;
    left: calc((${theme.spacings.tera} / 2) + ${theme.spacings.tera});
  `,
  [bottomMiddle]: () => css`
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  `,
  [bottomRight]: ({ theme }) => css`
    bottom: 0;
    right: calc((${theme.spacings.tera} / 2) + ${theme.spacings.tera});
  `,
};

const mobileAlignmnetStyles = {
  [topLeft]: ({ theme }) => css`
    top: ${theme.spacings.peta};
  `,
  [topMiddle]: ({ theme }) => css`
    top: ${theme.spacings.peta};
    left: 50%;
    transform: translateX(-50%);
  `,
  [topRight]: ({ theme }) => css`
    top: ${theme.spacings.peta};
    right: 0;
  `,
  [middleLeft]: () => css`
    top: 50%;
    transform: translateY(-50%);
  `,
  [middleMiddle]: () => css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  [middleRight]: () => css`
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  `,
  [bottomLeft]: ({ theme }) => css`
    bottom: ${theme.spacings.tera};
  `,
  [bottomMiddle]: ({ theme }) => css`
    bottom: ${theme.spacings.tera};
    left: 50%;
    transform: translateX(-50%);
  `,
  [bottomRight]: ({ theme }) => css`
    bottom: ${theme.spacings.tera};
    right: 0;
  `,
};

const alignmentStyles = ({
  alignmentVertical,
  alignmentHorizontal,
  theme,
  viewport,
}) => {
  const alignment = `${alignmentHorizontal}_${alignmentVertical}`;

  if (viewport === VIEWPORTS.MOBILE) {
    return mobileAlignmnetStyles[alignment]({ theme });
  }

  return desktopTabletAlignmnetStyles[alignment]({ theme });
};

const desktopTabletRoundedCornerStyles = {
  [topLeft]: ({ theme }) => css`
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};
  `,
  [topMiddle]: ({ theme }) => css`
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};
  `,
  [topRight]: ({ theme }) => css`
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};
  `,
  [middleLeft]: ({ theme }) => css`
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-right-radius: ${theme.borderRadius.exa};
  `,
  [middleMiddle]: ({ theme }) => css`
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
  `,
  [middleRight]: ({ theme }) => css`
    border-bottom-left-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
  `,
  [bottomLeft]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.mega};
    border-top-right-radius: ${theme.borderRadius.mega};
  `,
  [bottomMiddle]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.mega};
    border-top-right-radius: ${theme.borderRadius.mega};
  `,
  [bottomRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.mega};
    border-top-right-radius: ${theme.borderRadius.mega};
  `,
};

const mobileRoundedCornerStyles = {
  [topLeft]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
  `,
  [topMiddle]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [topRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [middleLeft]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
  `,
  [middleMiddle]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [middleRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [bottomLeft]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
  `,
  [bottomMiddle]: ({ theme }) => css`
    border-top-right-radius: ${theme.borderRadius.exa};
    border-bottom-right-radius: ${theme.borderRadius.exa};
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
  [bottomRight]: ({ theme }) => css`
    border-top-left-radius: ${theme.borderRadius.exa};
    border-bottom-left-radius: ${theme.borderRadius.exa};
  `,
};

const roundedCornerStyles = ({
  alignmentVertical,
  alignmentHorizontal,
  theme,
  viewport,
}) => {
  const alignment = `${alignmentHorizontal}_${alignmentVertical}`;

  if (viewport === VIEWPORTS.MOBILE) {
    return mobileRoundedCornerStyles[alignment]({ theme });
  }

  return desktopTabletRoundedCornerStyles[alignment]({ theme });
};

const baseStyles = ({ theme }) => css`
  label: badge;
  position: absolute;
  z-index: ${theme.zIndex.fullMediaBadge};
`;

const StyledPricingBadge = styled(PricingBadge)(
  baseStyles,
  alignmentStyles,
  roundedCornerStyles,
);

const StyledFeeBadge = styled(FeeBadge)(
  baseStyles,
  alignmentStyles,
  roundedCornerStyles,
);

const StyledTextBadge = styled(TextBadge)(
  baseStyles,
  alignmentStyles,
  roundedCornerStyles,
);

/**
 * Styled component: Badge in FullMedia.
 */
function Badge({
  alignmentHorizontal = ALIGNMENT_HORIZONTAL.TOP,
  alignmentVertical = ALIGNMENT_VERTICAL.LEFT,
  badge = {},
  viewport = VIEWPORTS.MOBILE,
}) {
  if (badge.contentType === BADGE_TYPES.PRICING_BADGE) {
    return (
      <StyledPricingBadge
        alignmentHorizontal={alignmentHorizontal}
        alignmentVertical={alignmentVertical}
        viewport={viewport}
        {...badge}
      />
    );
  }

  if (badge.contentType === BADGE_TYPES.FEE_BADGE) {
    return (
      <StyledFeeBadge
        alignmentHorizontal={alignmentHorizontal}
        alignmentVertical={alignmentVertical}
        viewport={viewport}
        {...badge}
      />
    );
  }

  if (badge.contentType === BADGE_TYPES.TEXT_BADGE) {
    return (
      <StyledTextBadge
        alignmentHorizontal={alignmentHorizontal}
        alignmentVertical={alignmentVertical}
        viewport={viewport}
        {...badge}
      />
    );
  }

  return null;
}

Badge.propTypes = {
  className: PropTypes.string,
  alignmentHorizontal: PropTypes.oneOf(values(ALIGNMENT_HORIZONTAL)),
  alignmentVertical: PropTypes.oneOf(values(ALIGNMENT_VERTICAL)),
  viewport: PropTypes.oneOf(values(VIEWPORTS)),
  /**
   * Product or Fee Badge in Contentful
   */
  badge: PropTypes.object,
};

/**
 * @component
 */
export default Badge;
