import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Body, List, Title } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import isPropValid from '@emotion/is-prop-valid';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedButton from '../components/EmbeddedButton';
import EmbeddedVariable from '../components/EmbeddedVariable';
import { getFields, getContentType } from '../util';

import {
  ALIGNMENT_ELEMENTS,
  TEXT_MODE,
} from '~/shared/components/FullMedia/constants';
import { ALIGNMENT_VERTICAL } from '~/shared/constants';
import slugify from '~/shared/util/slugify';
import Anchor from '~/shared/components/Anchor';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const richTextBaseStyles = {
  [ALIGNMENT_ELEMENTS.LEFT]: css`
    text-align: left;
  `,
  [ALIGNMENT_ELEMENTS.MIDDLE]: css`
    text-align: center;
  `,
  [ALIGNMENT_ELEMENTS.RIGHT]: css`
    text-align: right;
  `,
};

const richTextTextModeStyles = {
  [TEXT_MODE.BRIGHT]: css`
    color: var(--cui-fg-on-strong);
  `,
  [TEXT_MODE.DARK]: css`
    color: var(--cui-fg-normal);
  `,
};

const richTextStyles = ({ textMode, alignmentContent }) => css`
  ${richTextBaseStyles[alignmentContent]}
  ${richTextTextModeStyles[textMode]}
`;

const listStyles = ({ theme, size }) => {
  const sizeMap = {
    one: theme.spacings.byte,
    two: theme.spacings.kilo,
  };

  return css`
    margin-bottom: ${theme.spacings.mega};

    li:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: ${sizeMap[size]};
    }
  `;
};

const headlineStyles = ({
  theme,
  textMode,
  alignmentContent,
  hasPrimaryHeading,
  isSlide,
}) => css`
  ${richTextStyles({ textMode, alignmentContent })}
  margin-bottom: ${theme.spacings.kilo};
  word-break: break-word;
  flex-basis: 100%;
  line-height: 110%;
  color: ${textMode === TEXT_MODE.BRIGHT
    ? 'var(--cui-fg-on-strong)'
    : 'var(--cui-fg-normal)'};

  ${theme.mq.untilKilo} {
    /** Custom font size not in circuit ui */
    font-size: ${hasPrimaryHeading || isSlide ? '31px' : '28px'};
  }

  ${theme.mq.kiloToMega} {
    /** Custom font size not in circuit ui */
    font-size: ${hasPrimaryHeading || isSlide ? '44px' : '36px'};
    line-height: 110%;
  }

  ${theme.mq.mega} {
    /** Custom font size not in circuit ui */
    font-size: ${hasPrimaryHeading || isSlide ? '50px' : '36px'};
    line-height: 110%;
    margin-bottom: ${theme.spacings.mega};
  }
`;

const titleStyles = ({ theme, textMode, alignmentContent }) => css`
  ${richTextStyles({ textMode, alignmentContent })}
  margin-bottom: ${theme.spacings.kilo};
  word-break: break-word;
  flex-basis: 100%;
  line-height: 100%;
  color: ${textMode === TEXT_MODE.BRIGHT
    ? 'var(--cui-fg-on-strong)'
    : 'var(--cui-fg-normal)'};

  ${theme.mq.untilGiga} {
    font-size: ${theme.typography.title.four.fontSize};
  }
`;

const StyledHeadline = styled(Headline)(headlineStyles);
const StyledTitle = styled(Title)(titleStyles);

const paragraphAlignmentStyles = {
  [ALIGNMENT_ELEMENTS.LEFT]: () => css``,
  [ALIGNMENT_ELEMENTS.MIDDLE]: () => css`
    text-align: center;
  `,
  [ALIGNMENT_ELEMENTS.RIGHT]: ({ theme }) => css`
    ${theme.mq.giga} {
      padding-left: 20%;
    }
  `,
};

const paragraphStyles = ({
  theme,
  textMode,
  alignmentContent,
  isCircuitUiV4,
}) => css`
  ${richTextStyles({ textMode, alignmentContent })}
  ${paragraphAlignmentStyles[alignmentContent]({ theme })}

  margin-top: ${theme.spacings.bit};
  margin-bottom: 0;
  max-width: 100%;
  font-size: 16px;
  flex-basis: 100%;

  // @Note: Used in A/B tests for multi-product website
  ${isCircuitUiV4 &&
  alignmentContent === ALIGNMENT_VERTICAL.LEFT &&
  `${theme.mq.giga} {
    padding-right: 180px;
  }`}
`;

const markStyles = ({ textMode, alignmentContent }) => css`
  ${richTextStyles({ textMode, alignmentContent })}
  font-size: inherit !important;
`;

const Mark = styled(Body)(markStyles);

const Paragraph = styled(Body, {
  shouldForwardProp: (prop) => prop === 'as' || isPropValid(prop),
})(paragraphStyles);

const StyledList = styled(List, {
  shouldForwardProp: (prop) => prop === 'as' || isPropValid(prop),
})(richTextStyles, listStyles);

const MarkItalic = styled(Mark)(css`
  font-style: italic;
`);

export const renderNode = ({
  hasPrimaryHeading,
  alignmentContent = ALIGNMENT_VERTICAL.LEFT,
  textMode = TEXT_MODE.DARK,
  isSlide = false,
  trackingContentEntry = {},
} = {}) => ({
  [BLOCKS.HEADING_1]: ({ as }, children) => (
    <StyledHeadline
      as={as}
      size="one"
      alignmentContent={alignmentContent}
      textMode={textMode}
      id={slugify(children)}
      hasPrimaryHeading={hasPrimaryHeading}
      isSlide={isSlide}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_2]: ({ as }, children) => (
    <StyledHeadline
      as={as}
      size="one"
      alignmentContent={alignmentContent}
      textMode={textMode}
      id={slugify(children)}
      hasPrimaryHeading={hasPrimaryHeading}
      isSlide={isSlide}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.HEADING_3]: ({ as }, children) => (
    <StyledHeadline
      as={as}
      size="one"
      id={slugify(children)}
      alignmentContent={alignmentContent}
      textMode={textMode}
      hasPrimaryHeading={hasPrimaryHeading}
      isSlide={isSlide}
    >
      {children}
    </StyledHeadline>
  ),
  [BLOCKS.PARAGRAPH]: (node, children) => (
    <Paragraph
      size="one"
      alignmentContent={alignmentContent}
      textMode={textMode}
    >
      {children}
    </Paragraph>
  ),
  [BLOCKS.UL_LIST]: (node, children) => (
    <StyledList
      size="one"
      alignmentContent={alignmentContent}
      textMode={textMode}
    >
      {children}
    </StyledList>
  ),
  [BLOCKS.OL_LIST]: (node, children) => (
    <StyledList
      size="one"
      alignmentContent={alignmentContent}
      textMode={textMode}
      variant="ordered"
    >
      {children}
    </StyledList>
  ),
  [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
    const contentType = getContentType(node);
    const fields = getFields(node);

    switch (contentType) {
      case 'embeddedButton': {
        return (
          <EmbeddedButton
            {...fields}
            isInline={true}
            trackingContentEntry={trackingContentEntry}
          >
            {children}
          </EmbeddedButton>
        );
      }
      default:
        return null;
    }
  },
  [INLINES.EMBEDDED_ENTRY]: (node) => {
    const contentType = getContentType(node);
    const fields = getFields(node);

    if (
      node?.data?.target?.contentType === 'nt_mergetag' &&
      node?.data?.target?.nt_mergetag_id &&
      NINETAILED_IS_ENABLED
    ) {
      return <MergeTag id={node.data.target.nt_mergetag_id} />;
    }

    switch (contentType) {
      case 'embeddedVariable':
        return <EmbeddedVariable {...fields} />;
      default:
        return null;
    }
  },
  [INLINES.HYPERLINK]: (node, children) => (
    <Anchor href={node.data.uri} trackingContentEntry={trackingContentEntry}>
      {children}
    </Anchor>
  ),
});

export const renderNodeCustom = (props = {}) => {
  const {
    textMode = TEXT_MODE.DARK,
    alignmentContent = ALIGNMENT_VERTICAL.LEFT,
  } = props;

  return {
    ...renderNode(props),
    [BLOCKS.HEADING_1]: ({ as }, children) => (
      <StyledTitle
        as={as}
        size="one"
        alignmentContent={alignmentContent}
        textMode={textMode}
        id={slugify(children)}
      >
        {children}
      </StyledTitle>
    ),
    [BLOCKS.HEADING_2]: ({ as }, children) => (
      <StyledTitle
        as={as}
        size="two"
        alignmentContent={alignmentContent}
        textMode={textMode}
        id={slugify(children)}
      >
        {children}
      </StyledTitle>
    ),
    [BLOCKS.HEADING_3]: ({ as }, children) => (
      <StyledTitle
        as={as}
        size="three"
        id={slugify(children)}
        alignmentContent={alignmentContent}
        textMode={textMode}
      >
        {children}
      </StyledTitle>
    ),
    [BLOCKS.HEADING_4]: ({ as }, children) => (
      <Title
        as={as}
        size="four"
        id={slugify(children)}
        alignmentContent={alignmentContent}
        textMode={textMode}
      >
        {children}
      </Title>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Paragraph
        size="one"
        alignmentContent={alignmentContent}
        textMode={textMode}
        isCircuitUiV4
      >
        {children}
      </Paragraph>
    ),
  };
};

export const renderMark = ({
  alignmentContent = ALIGNMENT_VERTICAL.LEFT,
  textMode = TEXT_MODE.DARK,
} = {}) => ({
  [MARKS.BOLD]: (text) => (
    <Mark
      as="strong"
      size="one"
      alignmentContent={alignmentContent}
      textMode={textMode}
      variant="highlight"
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </Mark>
  ),
  [MARKS.ITALIC]: (text) => (
    <MarkItalic
      as="em"
      alignmentContent={alignmentContent}
      textMode={textMode}
      size="one"
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </MarkItalic>
  ),
});
